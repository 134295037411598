import { Results, Suggestion } from '@root/types/suggestion'
import { SuggestedFeature, UserProfileData } from '@root/types/profile'

import { EyeMood } from '@root/types/search'
import { Json } from '@root/types/common'
import { ProductType } from '@root/types/filters'

type GlassesType = Omit<ProductType, 'eyeglasses' | 'sunglasses'>
type AdvisorInformation = {
  suggestionEyeContrast: Json
  suggestionEyeHarmony: Json
  suggestionSunContrast: Json
  suggestionSunHarmony: Json
}

type Truthy<T> = T extends false | '' | 0 | null | undefined ? never : T

const retrieveSuggestion = (product: GlassesType, mood: EyeMood, advices: AdvisorInformation) => {
  switch (product) {
    case 'SUNGLASSES': {
      switch (mood) {
        case 'HARMONY':
          return advices.suggestionSunHarmony
        case 'CONTRAST':
          return advices.suggestionSunContrast
        default:
          return '{}'
      }
    }
    case 'EYEGLASSES': {
      switch (mood) {
        case 'HARMONY':
          return advices.suggestionEyeHarmony
        case 'CONTRAST':
          return advices.suggestionEyeContrast
        default:
          return '{}'
      }
    }
    default:
      return '{}'
  }
}

export const getUserSuggestions = ({
  data,
  defaultMood,
  defaultProductType,
}: {
  data: UserProfileData
  defaultMood?: string
  defaultProductType?: string
}): Suggestion => {
  const glassesType = (
    data.userInformation?.glassesType || defaultProductType
  ).toUpperCase() as GlassesType
  const mood = (glassesType === 'EYEGLASSES'
    ? data.userSearchInformation.eye_mood || defaultMood
    : data.userSearchInformation.sun_mood || defaultMood
  ).toUpperCase() as EyeMood
  const advisorInformation = data.advisorInformation
  const suggestion = retrieveSuggestion(glassesType, mood, advisorInformation)
  return JSON.parse(suggestion)
}

export const getUsersuggestedResults = (result: keyof Results, suggestion: Suggestion) => {
  function nonNullable<T>(value: T): value is Truthy<T> {
    return !!value
  }
  const suggestedResults = Object.entries(suggestion.results[result][0])
    .map(value => (value[1] as number) > 0 && value[0])
    .filter(nonNullable)
  return suggestedResults
}

type PartialSuggestedFeature = Omit<SuggestedFeature, 'size' | 'profileImageUrl'>
type SuggesterKeyMap = { v: string; k: keyof Results }
export const getAllSuggestions = (suggestions: Suggestion): PartialSuggestedFeature => {
  const keys: SuggesterKeyMap[] = [
    { k: 'glass_gender', v: 'glassGender' },
    { k: 'frame_shape', v: 'frameShape' },
    { k: 'frame_color', v: 'frameColor' },
    { k: 'frame_color_palette', v: 'frameColorPalette' },
    { k: 'frame_temple_thickness', v: 'templeThickness' },
  ]

  const res = keys.reduce((acc: { [key: string]: string[] }, cur: SuggesterKeyMap) => {
    acc[cur.v] = getUsersuggestedResults(cur.k, suggestions)
    return acc
  }, {})
  return res as PartialSuggestedFeature
}
