export const isMinimized = async () => {
  const store = await import('@root/store/index')
  const { app } = store.default.getState()

  return app.minimized
}

export const setMinimized = async (minimized: boolean) => {
  const store = await import('@root/store/index')
  const appActions = await import('@root/store/app/actions')

  const { dispatch } = store.default
  const { setMinimized } = appActions.default

  dispatch(setMinimized(minimized))
}
