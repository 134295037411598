import { useEffect, useState } from 'react'

import { isMobile, useMobileOrientation } from 'react-device-detect'
import { useConfig } from '@root/Context'
import { debounce } from 'lodash'

const RESIZE_DELAY = 300

interface Dimensions {
  width: number
  height: number
}

const getSelectorDimensions = (selector: string | HTMLElement) => {
  const { offsetWidth: width, offsetHeight: height } =
    typeof selector === 'string' ? (document.querySelector(selector) as HTMLElement) : selector

  return {
    width,
    height,
  }
}

export const useDimension = (
  elemRef: React.MutableRefObject<HTMLDivElement | HTMLElement | null>,
) => {
  const [elemDimension, setElemDimension] = useState<Dimensions | null>(null)

  useEffect(() => {
    function handleResize() {
      if (elemRef.current) {
        setElemDimension(getSelectorDimensions(elemRef.current))
      }
    }
    handleResize()

    const debouncedHandleResize = debounce(handleResize, RESIZE_DELAY)
    window.addEventListener('resize', debouncedHandleResize)

    return () => window.removeEventListener('resize', debouncedHandleResize)
  }, [elemRef])

  return elemDimension
}

const useSelectorDimensions = (): Dimensions => {
  const { selector } = useConfig()

  const [selectorDimensions, setSelectorDimensions] = useState(getSelectorDimensions(selector))

  useEffect(() => {
    let isMounted = true
    function handleResize() {
      if (isMounted) setSelectorDimensions(getSelectorDimensions(selector))
    }
    const debouncedHandleResize = debounce(handleResize, RESIZE_DELAY)

    window.addEventListener('resize', debouncedHandleResize)
    return () => {
      isMounted = false
      window.removeEventListener('resize', debouncedHandleResize)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const timeout = setTimeout(() => {
      setSelectorDimensions(getSelectorDimensions(selector))
    }, 1000)
    return () => clearTimeout(timeout)
  }, [selector])

  return selectorDimensions
}

export const useIsMobile = () => {
  return isMobile
}

export const useDeviceOrientation = () => {
  const { isPortrait, isLandscape, orientation } = useMobileOrientation()

  return {
    isPortrait,
    isLandscape,
    orientation,
  }
}

export default useSelectorDimensions
