/* eslint-disable @typescript-eslint/no-explicit-any */
import ReactDOM from 'react-dom'

import { useConfig } from '@root/Context'
import { ConfigWithCallbacks } from '@root/types/config'
import { useDevice } from '@hooks/useDevice'

import styled from 'styled-components'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useRefValue } from '@hooks/useRefValue'

const FrameAdvisorOverlay = styled.div<{ $zIndex: number; $isOverlay: boolean }>`
  position: fixed;
  inset: 0;
  z-index: ${({ $zIndex }) => $zIndex};
  background-color: ${({ theme, $isOverlay }) =>
    !$isOverlay ? theme.pallete.base.white : 'transparent'};
  overflow: scroll;
`

/*
 * Warning: the component will rerender again upon teleporting from one parent to another so the internal state of component will reset
 * Info: If this approached was not successful we need to set the parent position to "position: fixed" (for overlay view) but it might still giving as some issue on UI because the overlay might overlap
 */
export interface PortalType {
  // Need todo something before teleporting to another parent, this is useful for VM and capture component, need to close the camera before teleporting
  onBeforeTeleporting?: () => Promise<boolean>
}

export const PORTAL_ZINDEX = 9998

type PortalView = 'overlay' | 'embedded' | undefined

export interface PortalProps {
  teleport?: () => void
}

export function withPortal<T extends PortalProps>(
  PageComponent: React.ComponentType<T> | React.ForwardRefExoticComponent<T>,
  zIndex?: number,
  targetId?: string,
  isOverlay?: boolean,
) {
  const Portal: React.FC<T> = props => {
    const config = useConfig() as ConfigWithCallbacks
    const { inMobile } = useDevice()
    const [currentView, setCurrentView] = useState<PortalView>(
      window.innerWidth < 768 ? 'overlay' : 'embedded',
    )
    const portalView = useRef<PortalView>()
    const portal = useRef<PortalType>(null)
    const isTeleporting = useRef(false)
    const isMobileRef = useRefValue(inMobile)

    const selector = useRef<HTMLElement | null>(
      targetId ? document.getElementById(targetId) : document.querySelector(config.selector),
    )

    const setView = useCallback((view: PortalView) => {
      isTeleporting.current = false
      portalView.current = view
      setCurrentView(view)
    }, [])

    const teleport = useCallback(() => {
      const portalRef = portal.current

      if (isTeleporting.current && !!portalRef?.onBeforeTeleporting) {
        portalRef.onBeforeTeleporting().then(canTeleport => {
          if (canTeleport) {
            setView(isMobileRef.current ? 'overlay' : 'embedded')
          }
        })
      }
    }, [isMobileRef, setView])

    useEffect(() => {
      if (!selector.current || isTeleporting.current === true || isOverlay) return

      const portalRef = portal.current
      const newView: PortalView = inMobile ? 'overlay' : 'embedded'

      if (newView !== portalView.current) {
        isTeleporting.current = true

        if (!!portalRef?.onBeforeTeleporting && portalView.current !== undefined) {
          portalRef.onBeforeTeleporting().then(canTeleport => {
            if (canTeleport) setView(newView)
          })
        } else setView(newView)
      }
    }, [inMobile, setView])

    if (!selector.current) return null

    return ReactDOM.createPortal(
      currentView === 'overlay' || isOverlay ? (
        <FrameAdvisorOverlay $zIndex={zIndex || PORTAL_ZINDEX} $isOverlay={isOverlay || false}>
          <PageComponent
            {...props}
            ref={PageComponent.displayName ? portal : undefined}
            teleport={PageComponent.displayName ? teleport : undefined}
          />
        </FrameAdvisorOverlay>
      ) : (
        <PageComponent
          {...props}
          ref={PageComponent.displayName ? portal : undefined}
          teleport={PageComponent.displayName ? teleport : undefined}
        />
      ),
      currentView === 'overlay' || isOverlay ? document.body : selector.current,
    )
  }

  return Portal
}
