import React, { useEffect } from 'react'
import { fireAppLoaded, fireAppLoading } from '@root/utils/events'

import styled from 'styled-components'
import { PORTAL_ZINDEX, PortalProps, withPortal } from '@core/Portal'

const SpinnerWrapper = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .spinner-content--text {
    display: none;
    font-size: 10px;
  }
`

const SpinnerContent = styled.div`
  &,
  &:after {
    border-radius: 50%;
    width: 60px;
    height: 60px;
  }
  border-top: 11px solid rgba(199, 199, 199, 0.2);
  border-right: 11px solid rgba(199, 199, 199, 0.2);
  border-bottom: 11px solid rgba(169, 169, 169, 0.2);
  border-left: 11px solid #bababa;
  transform: translateZ(0);
  animation: faSpinner 1.1s infinite linear;

  @keyframes faSpinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`
export const Spinner: React.FC<{ $style?: React.CSSProperties } & PortalProps> = ({
  $style,
}): JSX.Element => {
  // const { t } = useTranslation()
  useEffect(() => {
    fireAppLoading()
    return () => {
      fireAppLoaded()
    }
  }, [])

  return (
    <SpinnerWrapper className="spinner-wrapper" style={$style}>
      <SpinnerContent className="spinner-content" />
      {/* NOTE: Do not translate it - ecommerce should override the spinner */}
      <div className="spinner-content--text">Loading</div>
    </SpinnerWrapper>
  )
}

export const SpinnerPortal = withPortal(Spinner, PORTAL_ZINDEX + 1)
