import * as t from 'io-ts'

import { FacescanPrivacyDecoder, RegionDecoder } from './config'

import { SuggestedSizeFeature } from '@root/types/profile'
import { optional } from '@root/utils/types'
import { measurementUnitsDecoder } from './filters'

export const ConfigCodec = t.type({
  /**
   * The CSS selector of the element in which the widget will be printed
   */
  selector: t.string,

  /**
   * The language of the widget (if not present, there is a fallback on English)
   *
   * @default: en-US
   */
  locale: t.string,

  /**
   * The source of the facescan integration
   *
   * Possible values:
   * - FASA_RBCOM
   * - FASA_OOCOM
   * - FASA_SGHCOM
   * - FASA_LCCOM
   * - FASA_GLASSESCOM
   * - FASA_COSTACOM
   *
   */
  facescanSource: t.string,

  /**
   * The region of the e-commerce integration - used by facescan app
   *
   * Possible values:
   * - US
   * - EU
   * - AU
   *
   */
  facescanRegion: RegionDecoder,

  /**
   * Privacy policy propertires used by facescan app
   */
  facescanPrivacy: FacescanPrivacyDecoder,

  /**
   * The key of Virtual Mirror Component
   *
   */
  frameAdvKey: t.string,
  /**
   * The absolute path of the language file in json format
   *
   */
  overrideTranslationsUrl: optional(t.string),
  handleResults: t.Function,

  /**
   * Customize this property to choose which measurement unit will be available.
   * The possible values are:
   * - mm
   * - inch
   *
   * default: ['mm']
   *
   * For instance set this property to `measurementUnits: ['inch', 'mm']`
   * in order to have the selection of the units on the hindge2hindge survey step
   */
  measurementUnits: optional(t.array(measurementUnitsDecoder)),

  /**
   * This property is used to set a color for the loader animation. Should be set in HEX format. Ex: #000000. Do not cut to #000.
   */
  loaderAnimationColor: optional(t.string),
})

type ConfigCallbacks = {
  /** This function is used to retrieve the information about the suggested size */
  handleResults: (data: SuggestedSizeFeature) => void
}

export type Config = t.TypeOf<typeof ConfigCodec>

export type ConfigParams = Omit<Config, 'selector'>

export type ConfigWithCallbacks = Config & ConfigCallbacks
