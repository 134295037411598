/* eslint-disable */
import { Interval, Data as SizeAdvisorData } from '@root/types/sizeadvisor'
import { SuggestedSize, SuggestedSizeFeature } from '@root/types/profile'

export const getSuggestedSize = (data: SizeAdvisorData): SuggestedSizeFeature => {
  return {
    label: data?.frameSizeFamily,
    optimalHinge: data?.optimalHinge,
    maxHinge: data?.maxHinge,
    minHinge: data?.minHinge,
  }
}

export const getInRangeSizes = (intervals: Interval[]): string => {
  const ranges: string[] = []
  intervals.forEach(({ enabledList, sizeName }) => {
    const inRangeHinges = enabledList.reduce((acc, curr) => (curr.inRange ? acc + 1 : acc), 0)
    const isSizeInRange = inRangeHinges > 1
    if (isSizeInRange && !ranges.includes(sizeName)) {
      ranges.push(sizeName)
    }
  })
  return ranges.join('/')
}

type Size = { [key: string]: number }

export const getSuggestedSizeLabels = ({
  sizes,
  optimalHinge,
  lowerBound,
  upperBound,
}: {
  sizes: { [key: string]: number }
  optimalHinge: number
  lowerBound: number // 4
  upperBound: number // 3
}): SuggestedSize => {
  // x < -3			Too tight
  // -3 ≤ x ≤ 4			Next best size
  // -3 ≤ x ≤ 4, the closest to 0			Recommended badge
  // x > 4			Too loose
  // 'tight' | 'next-best-size' | 'recommended' | 'loose'
  const results: SuggestedSize = {}

  const lowLimit = optimalHinge - upperBound
  const upLimit = optimalHinge + lowerBound

  const recommendedGaps: Size = {}
  const looseGaps: Size = {}
  const tightGaps: Size = {}

  Object.entries(sizes).forEach(c => {
    const [size, hinge] = c

    const diff = hinge - optimalHinge
    if (diff <= lowerBound && diff >= -Math.abs(upperBound)) {
      // res[size] = 'recommended'
      recommendedGaps[size] = diff
    }

    // too tight
    if (hinge < lowLimit) {
      tightGaps[size] = diff
    }

    // Too loose
    if (hinge > upLimit) {
      looseGaps[size] = diff
    }
  })

  // get the min loose
  const loose = Object.entries(looseGaps).reduce(
    (prev: [string, number] | null, cur: [string, number]) => {
      if (!prev || Math.abs(cur[1]) <= Math.abs(prev[1])) {
        return cur
      }
      return prev
    },
    null,
  )

  // get the min tight
  const tight = Object.entries(tightGaps).reduce(
    (prev: [string, number] | null, cur: [string, number]) => {
      if (!prev || Math.abs(cur[1]) <= Math.abs(prev[1])) {
        return cur
      }
      return prev
    },
    null,
  )

  // get the min abs recommended value
  const recommended = Object.entries(recommendedGaps).reduce(
    (prev: [string, number] | null, cur: [string, number]) => {
      if (!prev || Math.abs(cur[1]) <= Math.abs(prev[1])) {
        return cur
      }
      return prev
    },
    null,
  )

  // get the max abs of remaining recommended
  const next = Object.entries(recommendedGaps)
    .filter(el => recommended && el[0] !== recommended[0])
    .reduce((prev: [string, number] | null, cur: [string, number]) => {
      if (!prev || Math.abs(cur[1]) > Math.abs(prev[1])) {
        return cur
      }
      return prev
    }, null)

  if (tight) {
    results[tight[0]] = 'tight'
  }

  if (loose) {
    results[loose[0]] = 'loose'
  }

  if (next) {
    results[next[0]] = 'next-best-size'
  }

  if (recommended) {
    results[recommended[0]] = 'recommended'
  }

  return results
}

export const dedupSuggestedSizeNames = (intervals: Interval[]) => {
  const bestFitIntervalIndex = 1

  return intervals.map((interval, index, arr) => {
    let { sizeName, ...rest } = interval
    const isDuplicateSize =
      index !== bestFitIntervalIndex && interval.sizeName === arr[bestFitIntervalIndex].sizeName
    if (isDuplicateSize) {
      sizeName = sizeName.replace(/([\s\S])+/, '')
    }
    return { ...rest, sizeName }
  })
}
