import debug from 'debug'

const APP_NAME = 'FA'
type ErrorInput = string | object | undefined

type ErrorLevel = 'trace' | 'info' | 'warn' | 'error' | 'debug'

const COLOURS: Record<ErrorLevel, string> = {
  trace: 'lightblue',
  debug: 'lightgreen',
  info: 'blue',
  warn: 'orange',
  error: 'red',
}

/** Generate a debug message */
const debugMessage = (level: ErrorLevel, message: ErrorInput, source?: string) => {
  // Set the prefix which will cause debug to enable the message
  const namespace = `${APP_NAME}:${level}`
  const createDebug = debug(namespace)

  if (createDebug.enabled) {
    // Set the colour of the message based on the level
    createDebug.color = COLOURS[level]

    if (source) {
      createDebug(source, message)
    } else {
      createDebug(message)
    }
  }
}

/**
 *
 * How to use:
 *  import { logger } from '@root/utils/logger'
 *  logger.info('My error message', 'PDP');
 *
 * To enable logging is necessary to add an entry 'debug'
 * on browser's localStorage with this value:
 *
 *  FA:<level|*>
 *
 * Level should be a value such as: trace | info | warn | error | *
 * in order to enable a specific logging level.
 *
 *  es: localStorage.debug = 'FA:*'
 *
 * further info: https://levelup.gitconnected.com/step-up-your-console-messaging-game-in-your-react-app-42eee17659ec
 */

class Logger {
  static trace(message: ErrorInput, source?: string) {
    return debugMessage('trace', message, source)
  }

  static debug(message: ErrorInput, source?: string) {
    return debugMessage('debug', message, source)
  }

  static info(message: ErrorInput, source?: string) {
    return debugMessage('info', message, source)
  }

  static warn(message: ErrorInput, source?: string) {
    return debugMessage('warn', message, source)
  }

  static error(message: ErrorInput, source?: string) {
    return debugMessage('error', message, source)
  }
}

const logger = Logger
export { logger }
