import 'proxy-polyfill'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import * as t from 'io-ts'

import { Config, ConfigCodec, ConfigWithCallbacks } from '@root/types/config'
import { IFrameAdvisorWidget, ISizeAdvisorWidget } from '@root/types/widget'
import React, { Suspense } from 'react'
import {
  Config as SizeAdvConfig,
  ConfigCodec as SizeAdvConfigCodec,
  ConfigWithCallbacks as SizeAdvConfigWithCallbacks,
} from '@root/types/sizeadvisorconfig'
import { closeApp, getSuggestedSizes, getUserData } from '@root/utils/app'

import { Provider } from 'react-redux'
import ReactDOM from 'react-dom'
import { Spinner } from '@components/Spinner'
import { fold } from 'fp-ts/lib/Either'
import { formatValidationErrors } from 'io-ts-reporters'
import { getSuggestedSizeLabels } from '@root/utils/sizeadvisor'
import { logger } from '@root/utils/logger'
import { pipe } from 'fp-ts/lib/function'
import pkgVersion from './version'
import { isMinimized, setMinimized } from './utils/store'
// import store from '@root/store'
// import storeSizeAdv from '@root/store/sizeAdvStore'

import(/* webpackPrefetch: true */ '@luxottica/capture-component')
import(/* webpackPrefetch: true */ '@luxottica/vto-core')
import(/* webpackPrefetch: true */ '@luxottica/virtual-mirror')

const FrameAdvisor = React.lazy(() => import('./FrameAdvisor'))
const Facescan = React.lazy(() => import('./Facescan'))
const SizeAdvisor = React.lazy(() => import('./SizeAdvisor'))

if (process.env.REACT_APP_DEBUG === 'true') {
  localStorage.debug = 'FA:*'
}

window.faGlobalConfig = window.faGlobalConfig || {}

class FrameAdvisorWidget implements IFrameAdvisorWidget {
  config?: ConfigWithCallbacks

  constructor(config: ConfigWithCallbacks) {
    this.config = config

    // set default values
    if (!this.config.defaultMood) {
      this.config.defaultMood = 'harmony'
    }
    if (!this.config.defaultProductType) {
      this.config.defaultProductType = 'sunglasses'
    }
    if (!this.config.productTypes) {
      this.config.productTypes = ['sunglasses', 'eyeglasses']
    }

    if (
      !this.config.productRequestRowsLimit ||
      this.config.productRequestRowsLimit < 1 ||
      this.config.productRequestRowsLimit > 50
    ) {
      this.config.productRequestRowsLimit = 24
    }

    if (!this.config.startMinimized) {
      this.config.startMinimized = false
    }

    if (typeof this.config.enableSizeAdvisor === 'undefined') {
      this.config.enableSizeAdvisor = true
    }

    // FASA-1145 - enable size filter
    // TODO: need to delete this params in the future once we remove unused components
    this.config.enableSizeAdvisor = true

    if (typeof this.config.enableVideoMode === 'undefined') {
      this.config.enableVideoMode = true
    }

    if (typeof this.config.closePDPonAddToBag === 'undefined') {
      this.config.closePDPonAddToBag = false
    }

    // global config
    window.faGlobalConfig = {
      isTestCMS: this.config.isTestCMS || false,
    }

    logger.debug(config, 'configuration')
  }

  async render() {
    if (!this.config) {
      throw new Error('Config is not defined')
    }

    const { selector } = this.config
    const container = document.querySelector(selector)
    if (!container) {
      throw new Error(
        `You requested Frame Advisor to render inside the element with the selector ${selector}, but there is no such element in the document. Check the "selector" parameter in Frame Advisor initialization or your DOM.`,
      )
    }

    // CHECK IF MINIMIZED
    const minimized = await this.isMinimize()
    if (minimized) {
      this.maximize()
    } else {
      import('@root/store').then(store => {
        if (this.config) {
          ReactDOM.render(
            <Provider store={store.default}>
              <Suspense fallback={<Spinner />}>
                <FrameAdvisor config={this.config} />
              </Suspense>
            </Provider>,
            container,
          )
        }
      })
    }
  }

  async isMinimize() {
    if (this) {
      const minimize = await isMinimized()
      return minimize
    }
  }

  async maximize() {
    if (this) {
      await setMinimized(false)
    }
  }

  async closeApp() {
    closeApp(this.config)
  }

  async getUserData() {
    return getUserData(this.config)
  }

  async getSuggestedSizes(sizes: { [key: string]: number }, lowerBound = 3, upperBound = 4) {
    if (this.config?.enableSizeAdvisor) {
      return getSuggestedSizes(sizes, lowerBound, upperBound)
    }
  }

  static version() {
    return pkgVersion
  }

  static new(config: ConfigWithCallbacks) {
    const onLeft = (errors: t.Errors) => {
      const errorsToReport = formatValidationErrors(errors)
      throw Error(
        `Frame Advisor was initialized with a wrong configuration object, ${
          errorsToReport.length
        } errors found:\n - ${errorsToReport.join('\n - ')}.`,
      )
    }

    const onRight = (config: Config) => new FrameAdvisorWidget(config as ConfigWithCallbacks)

    return pipe(ConfigCodec.decode(config), fold(onLeft, onRight))
  }
}

class SizeAdvisorWidget implements ISizeAdvisorWidget {
  config?: SizeAdvConfigWithCallbacks

  constructor(config: SizeAdvConfigWithCallbacks) {
    this.config = config

    // set default values
    if (typeof this.config.measurementUnits === 'undefined') {
      this.config.measurementUnits = ['mm']
    }

    logger.debug(config, 'configuration')
  }

  async render() {
    if (!this.config) {
      throw new Error('Config is not defined')
    }

    const { selector } = this.config
    const container = document.querySelector(selector)
    if (!container) {
      throw new Error(
        `You requested Size Advisor to render inside the element with the selector ${selector}, but there is no such element in the document. Check the "selector" parameter in Frame Advisor initialization or your DOM.`,
      )
    }

    import('@root/store/sizeAdvStore').then(storeSizeAdv => {
      if (this.config) {
        ReactDOM.render(
          <Provider store={storeSizeAdv.default}>
            <Suspense fallback={<Spinner />}>
              <SizeAdvisor config={this.config} />
            </Suspense>
          </Provider>,
          container,
        )
      }
    })
  }

  async closeApp() {
    closeApp(this.config)
  }

  static getSuggestedSizes(
    hinge: number,
    sizes: { [key: string]: number },
    lowerBound = 3,
    upperBound = 4,
  ) {
    return getSuggestedSizeLabels({
      sizes,
      optimalHinge: hinge,
      lowerBound,
      upperBound,
    })
  }

  static version() {
    return pkgVersion
  }

  static new(config: SizeAdvConfigWithCallbacks) {
    const onLeft = (errors: t.Errors) => {
      const errorsToReport = formatValidationErrors(errors)
      throw Error(
        `Size Advisor was initialized with a wrong configuration object, ${
          errorsToReport.length
        } errors found:\n - ${errorsToReport.join('\n - ')}.`,
      )
    }

    const onRight = (config: SizeAdvConfig) =>
      new SizeAdvisorWidget(config as SizeAdvConfigWithCallbacks)

    return pipe(SizeAdvConfigCodec.decode(config), fold(onLeft, onRight))
  }
}

//NEW FACE SCAN UI
class FacescanWidget {
  config?: ConfigWithCallbacks

  constructor(config: ConfigWithCallbacks) {
    this.config = config
  }

  async render() {
    if (!this.config) {
      throw new Error('Config is not defined')
    }

    const { selector } = this.config
    const container = document.querySelector(selector)
    if (!container) {
      throw new Error(
        `You requested Frame Advisor to render inside the element with the selector ${selector}, but there is no such element in the document. Check the "selector" parameter in Frame Advisor initialization or your DOM.`,
      )
    }

    import('@root/store').then(store => {
      if (this.config) {
        ReactDOM.render(
          <Provider store={store.default}>
            <Suspense fallback={<Spinner />}>
              <Facescan config={this.config} />
            </Suspense>
          </Provider>,
          container,
        )
      }
    })
  }
}

export { FrameAdvisorWidget, SizeAdvisorWidget, FacescanWidget }
