// import { useDimension } from '@root/utils/layout'
// import { useRef } from 'react'
import useSelectorDimensions from '@root/utils/layout'
import { isMobile, isTablet } from 'react-device-detect'

export const useDevice = () => {
  const { width } = useSelectorDimensions()
  const inMobile = (isMobile && !isTablet) || width < 768

  // const bodyRef = useRef(document.body)
  // const dimension = useDimension(bodyRef)
  // const inMobile = (isMobile && !isTablet) || (!!dimension && dimension.width < 768)

  return {
    inMobile,
    isTablet,
  }
}
