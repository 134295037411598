//FA events
const showFacescanApp = new Event('fa-show-facescanapp')
const loading = new Event('fa-loading')
const loaded = new Event('fa-loaded')
const minimizeApp = new Event('fa-minimize')
const maximizeApp = new Event('fa-maximize')
const closeApp = new Event('fa-close')

export const fireShowFacescanApp = () => {
  const body = document.querySelector('body')

  if (body) {
    body.dispatchEvent(showFacescanApp)
  }
}

export const fireAppLoading = () => {
  const body = document.querySelector('body')
  if (body) {
    body.dispatchEvent(loading)
  }
}

export const fireAppLoaded = () => {
  const body = document.querySelector('body')
  if (body) {
    body.dispatchEvent(loaded)
  }
}

export const fireMinimizeApp = () => {
  const body = document.querySelector('body')
  if (body) {
    body.dispatchEvent(minimizeApp)
  }
}

export const fireMaximizeApp = () => {
  const body = document.querySelector('body')
  if (body) {
    body.dispatchEvent(maximizeApp)
  }
}

export const fireCloseApp = () => {
  const body = document.querySelector('body')
  if (body) {
    body.dispatchEvent(closeApp)
  }
}

//SA events
const saLoaded = new Event('sa-loaded')
const saCloseApp = new Event('sa-close')

export const saFireAppLoaded = () => {
  const body = document.querySelector('body')
  if (body) {
    body.dispatchEvent(saLoaded)
  }
}

export const saFireCloseApp = () => {
  const body = document.querySelector('body')
  if (body) {
    body.dispatchEvent(saCloseApp)
  }
}
